import {
    Bars4Icon,
    GlobeAmericasIcon, NewspaperIcon,
    PhoneIcon, RectangleGroupIcon,
    SquaresPlusIcon,
    SunIcon, TagIcon,
    UserGroupIcon
} from "@heroicons/react/24/solid";
import React, {useState} from "react";
import {  useRouter } from "next/navigation";

import Link from 'next/link'

import {
    Collapse,
    IconButton,
    ListItem,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Typography
} from "@material-tailwind/react";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {Squares2X2Icon} from "@heroicons/react/16/solid";
import {OrganizationToggle} from "@/components/topnav/OrganizationToggle";

const navListMenuItems = [
    {
        title: "Dashboard",
        description: "Find and manage your assets",
        icon: SquaresPlusIcon,
        destination: "/manage",
    },
    {
        title: "Assets",
        description: "Find and manage your assets",
        icon: SquaresPlusIcon,
        destination: "/manage/asset",
    },
    {
        title: "Applications",
        description: "Meet and learn about our dedication",
        icon: UserGroupIcon,
        destination: "/manage/application",
    },

    {
        title: "Tenants",
        description: "Reach out to us for assistance or inquiries",
        icon: GlobeAmericasIcon,
        destination: "/manage/tenant",
    },
    {
        title: "Asset Site Map",
        description: "Manage asset site maps",
        icon: GlobeAmericasIcon,
        destination: "/manage/site-map",
    },
    {
        title: "Legal Entities",
        description: "Manage legal entities",
        icon: GlobeAmericasIcon,
        destination: "/manage/legal-entity",
    },
    {
        title: "Organization Settings",
        description: "Manage access and settings for the organization",
        icon: GlobeAmericasIcon,
        destination: "/manage/settings",
    },
];

export function ManageMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const renderItems = navListMenuItems.map(
        ({ icon, title, description, destination }, key) => (
            <Link href={destination} key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg">
                    <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 text-gray-900 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="black"
                            className="flex items-center text-sm font-bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </Link>
        )
    );

    return (
        <Menu
            open={isMenuOpen}
            handler={setIsMenuOpen}

            placement="bottom-start"
            allowHover={false}
        >
            <MenuHandler>
                <Typography as="div" variant="small" className="font-medium">

                    <IconButton variant="text">
                        <Squares2X2Icon className="h-7 w-7 text-blue-500" />
                    </IconButton>
                </Typography>
            </MenuHandler>
            <MenuList className="hidden max-w-screen-xl rounded-xl block">


                <ul className="grid grid-cols-2 gap-y-2 outline-none outline-0">

                    {renderItems}
                </ul>
            </MenuList>
        </Menu>
    );
}