"use client";
import { Inter } from "next/font/google";
import "./globals.css";
import TopNavigationBar from "@/components/topnav/TopNavigationBar";
import {ApolloWrapper} from "@/app/ApolloWrapper";
import React from "react";
import {Toaster} from "react-hot-toast";

const inter = Inter({ subsets: ["latin"] });

export default function RootLayout({
  children,
}: Readonly<{ children: React.ReactNode;}>) {
  return (
      <html lang="en">
      <head>
          <title>Rental Flow</title>
          <script defer data-domain="rentalflow.ai" src="https://plausible.io/js/script.js"></script>
      </head>


      <body className={inter.className + " "}>
      <ApolloWrapper>
      <Toaster/>
              <TopNavigationBar/>
              <div className={"p-2 pt-3 h-[calc(100vh-5vh)]"}>
                  {children}
              </div>



          </ApolloWrapper>
      </body>
      </html>
  );
}
