import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const PmStore = create(
	persist(
		(set, get) => ({
			isAuthenticated: false,
			selectedOrganization: null,
			token: null,
			knownOrganizations: [],
			claims: {},

			// setToken: (tok) => set((state) => ({ token: tok, isAuthenticated: true })),
			setToken: (newToken) =>
				set((state) => ({
					token: newToken,
					isAuthenticated: true,
					claims: (decodeJwt(newToken)).claims,
				})),

			resetToken: () =>
				set((state) => ({
					token: null,
					isAuthenticated: false,
					claims: {},
					knownOrganizations: [],
					selectedOrganizationId: null,
				})),

			setSelectedOrg: (org) => {
				set((state) => ({
					selectedOrganization: org,
				}));
			},

			setKnownOrganizations: (orgs) =>
				set((state) => ({
					knownOrganizations: orgs,
				})),
		}),
		{
			name: "pm-store", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		}
	)
);

function decodeJwt(token) {
	const [h64, c64, signature] = token.split('.');
	const jwt = {
		header: (h64) ? JSON.parse(atob(h64)) : null,
		claims: (c64) ? JSON.parse(atob(c64)) : null,
		signature
	}
	return jwt;
}